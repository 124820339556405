import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Hero from "src/components/Hero"
import Container from "src/components/Container"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import { Paragraph } from "src/components/Typography"
import {
  ArticleContent,
  TextColumn,
  SharingColumn,
  SideColumn,
} from "src/templates/article"
import { pageNormalizer } from "src/utils/contentNormalizers"

export const query = graphql`
  query careersPageByIdQuery($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFragment
    }
    allWordpressWpJob {
      nodes {
        id
        acf {
          job_reference
        }
        excerpt
        path
        title
      }
    }
  }
`

export default props => {
  const { wordpressPage, allWordpressWpJob } = props.data

  const { title, content, path, featured_media } = pageNormalizer(wordpressPage)
  const positions = allWordpressWpJob.nodes

  const parsedContent = parseContent(content)

  return (
    <>
      <SEO title={title} image={featured_media}/>
      <Ad type="page/before_header" />
      <PageWrapper>
        <Header />
        <Hero title={title} image={featured_media} />
        <Container>
          <Content>
            <TextColumn>
              {parsedContent}
              {positions.map((position, index) => {
                const {
                  title,
                  path,
                  acf: { job_reference },
                  excerpt,
                } = pageNormalizer(position)
                return (
                  <Position key={index}>
                    <PositionTitle>
                      <Link to={path}>{title}</Link>
                    </PositionTitle>
                    {job_reference && (
                      <PositionSubtitle>{job_reference}</PositionSubtitle>
                    )}
                    <Paragraph>{excerpt}</Paragraph>
                    <PositionLink to={path}>See position</PositionLink>
                  </Position>
                )
              })}
            </TextColumn>

            <SharingColumn>
              <SocialMediaSharing title={title} path={path} />
            </SharingColumn>
            <SideColumn>
              <Ad type="page/sidebar" />
            </SideColumn>
          </Content>
        </Container>
      </PageWrapper>
      <Ad type="page/before_footer" />
    </>
  )
}

const Content = styled(ArticleContent)`
  margin-bottom: 70px;
`

const StyledSharingColumn = styled(SharingColumn)`
  padding-top: 0;
`

const Position = styled.div`
  &:nth-child(n + 2) {
    margin-top: 72px;
  }
`

const PositionTitle = styled.h2`
  margin-bottom: 26px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const PositionSubtitle = styled(Paragraph)`
  margin-top: -20px;
  margin-bottom: 25px;
`

const PositionLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
`
